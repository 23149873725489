<template>
    <div class="NoticeList">
        <div class="main">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>进场交易</el-breadcrumb-item>
            </el-breadcrumb>
            <el-card >
                <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" ref="dataFormQuery"> 
                    <el-form-item label="项目名称">
                        <el-input v-model="dataForm.targetName" placeholder="项目名称" clearable> </el-input>
                    </el-form-item>
                    <el-form-item label="项目编号">
                        <el-input v-model="dataForm.projectno" placeholder="项目编号" clearable></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="进场类型" prop="targettypecascaderlist" >
                        <el-cascader
                                placeholder="请选择进场类型"
                                v-model="entryType"
                                :options="JinChangOptions"
                                clearable
                                >
                        </el-cascader>
                    </el-form-item> -->
                    <el-form-item label="所在地">
                        <el-input v-model="dataForm.areacode" placeholder="所在地" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button  type="primary" @click="getDataList()">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="danger" @click="reset">重置</el-button>
                    </el-form-item>
                    <div class="type2">
                       
                    </div>
                </el-form>

            </el-card>
            <el-card class="box-card" style="margin-top: 5px;">
                <!-- <div class="main-title">招商公告</div> -->
                <div class="main-body">
                    <div class="body-table">
                        <div class="table-list">
                            <el-table
                                    :data="JinChangJiaoYiList"
                                    stripe
                                    @row-click="gotoDetails"
                                    style="width: 100%">

                                <el-table-column
                                        prop="targetName"
                                        label="项目名称"
                                        :show-overflow-tooltip='true'
                                        align="center" header-align="center" 
                                        width="260">
                                </el-table-column>
                                <el-table-column
                                        prop="projectno"
                                        label="项目编号"
                                        :show-overflow-tooltip='true'
                                        align="center" header-align="center" 
                                        width="220">
                                </el-table-column>
                                <el-table-column
                                        prop="entryType"
                                        align="center" header-align="center" 
                                        label="进场类型">
                                         <template slot-scope="scope">
                                            <span >{{JinChangLeiXingType(scope.row.entryType)}}</span>
                                        </template>
                                </el-table-column>
                                 <el-table-column
                                        prop="overprice"
                                        align="center" header-align="center" 
                                        label="转让底价">
                                        <template slot-scope="scope">
                                            <span >{{(scope.row.overprice)}} 万元</span>
                                        </template>
                                </el-table-column>
                                <el-table-column
                                        prop="areacode"
                                        label="所在地">
                                </el-table-column>
                                <el-table-column
                                        prop="disclosureStartTime"
                                        align="center" header-align="center" 
                                        label="发布时间">
                                 
                                </el-table-column>
                            </el-table>
                        </div>
                        <!--    分页-->
                        <div class="block" align="right">
                            <el-pagination
                                    @size-change="sizeChangeHandle"
                                    @current-change="currentChangeHandle"
                                    :current-page="pageIndex"
                                    :page-sizes="[10, 20, 50, 100]"
                                    :page-size="pageSize"
                                    :total="totalPage"
                                    layout="total, sizes, prev, pager, next, jumper">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import {CodeToText} from "element-china-area-data";

    export default {
        data() {
            return {
                hoverClass99: "",
                hoverClass: "",
                activeClass99: '',
                towncode: '',
                provincecode:'',
                citycode: '',
                townflg: true,
                cityflg: true,
                fjzcdis:false,
                townlist:[],
                citylist:[],
                activeClass: "",
                provincelist:[],
                // 进场交易列表 数据
                JinChangJiaoYiList: [],
                selectedOptions: [],
                pageIndex: 1,
                pageSize: 10,
                totalPage: 0,
                forsearch:{
                    outbidstate: '',
                    targettypecascader:'',
                    provincecode:'',
                    city: '',
                    town: '',

                },
                JinChangOptions: [
                     {
                    value: '0',
                    label: '实物资产'
                }, {
                    value: '1',
                    label: '股权'
                }],
                targettypecascaderlist:[],
                //分页数据
                dataForm: {
                    targetName: '',
                    projectno: '',
                    entryType: '',
                    areacode: '',
                },
                dataListLoading: false,
                qualityTableStyle: {
                    'background': 'whitesmoke',
                    'font-weight': 'bold',
                    'font-size': ' 12px',
                    'font-family': "Microsoft YaHei",
                    'color': '#333333',
                    'text-align': 'center',
                },
                a1s: [
                    { name: "不限", type: "", classid: 1 }
                ],
            }
        },
        activated(){
            this.getDataList()
        },
        created() {
        },
        mounted() {
        },
        methods: {
           
            // 获取数据列表
            getDataList() {
                this.$fetch('/shoppingui/ecentrytransaction/page', {
                    'page': this.pageIndex,
                    'limit': this.pageSize,
                    'targetName':this.dataForm.targetName,
                    'projectno':this.dataForm.projectno,
                    'entryType':this.dataForm.entryType,
                    'areacode':this.dataForm.areacode,
                }, true).then((data) => {
                    if (data && data.code === 0) {
                        this.JinChangJiaoYiList = data.data.list;
                        this.totalPage = data.data.total;
                    } else {
                        this.JinChangJiaoYiList = []
                        this.totalPage = 0
                        this.$message({
                            message: data.msg,
                            type: 'error',
                            showClose: 'true'
                        })
                    }
                })

            },
          
            reset(){
                this.$refs.dataFormQuery.resetFields() ;
                 Object.assign(this.dataForm, this.$options.data().dataForm)
                this.getDataList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageIndex = 1
                //调用查询信息接口
                this.getDataList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageIndex = val
                //调用查询信息接口
                this.getDataList()
            },
            gotoDetails(row){
                let queryData = {};
                queryData.id = row.id;
                this.$router.push({path: "/JinChangJiaoYiOne", query: queryData});
            },
            // 格式化 进场类型
            JinChangLeiXingType(val){
                if(val==0){
                    return '实物资产'
                }else if(val ==1){
                    return '股权'
                }else{
                    return '未分类'
                }
                },

        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/index.css";
    @import "../../assets/css/page/bidding.scss";
    @import "../../assets/css/page/NoticeList.scss";
    /* 更改element-UI按钮样式 */
    .el-button--text {
        color: #b0b0b0;
    }

    .el-button--text:hover {
        color: #b0b0b0;
        font-weight: bold;
    }

    .el-button--text:focus {
        color: #000000;
        font-weight: bold;
    }
   .el-table {
    /deep/tbody tr:hover>td {
        cursor: pointer;
        background-color:#fde8ea;
        // font-size: 18px;
        font-weight: 500;
        }
    }
    // .card_imgsize{
    //   width: 65px;
    //   height: 65px;
    //   object-fit: cover;
    // }

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 100px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 100px;
  }

</style>
